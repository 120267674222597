<template>
    <v-container fluid fill-height class="auth_page">
        <v-row justify="center">
            <v-card class="elevation-1">
                <v-container fluid class="pt-0 pb-1">
                    <AuthForm :header="$t('auth.forgot_password_text')" :is_loading="loading"
                              @submit="submit">
                        <template slot="content">
                            <v-col cols="12">
                                <v-text-field id="email" name="email" type="email" class="custom_field"
                                              v-model="email" :label="$t('general.email')"
                                              prepend-inner-icon="person" outlined clearable
                                              @input="$v.email.$touch()" @blur="$v.email.$touch()"
                                              :error-messages="fieldErrors()">
                                </v-text-field>
                            </v-col>
                        </template>
                    </AuthForm>
                </v-container>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, email} from 'vuelidate/lib/validators'
    import AuthForm from './../../components/AuthForm.vue'

    export default {
        components: {
            AuthForm
        },
        mixins: [validationMixin],
        validations: {
            email: {
                required,
                email
            }
        },
        data: () => ({
            email: null,
            loading: false
        }),
        methods: {
            // form field errors
            fieldErrors() {
                const errors = [];
                if (!this.$v.email.$dirty) return errors;
                !this.$v.email.email && errors.push(this.$t('validation.email'));
                !this.$v.email.required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // make API call to send change password email
            async submit() {
                this.$v.email.$touch();

                // if email is valid
                if (!this.$v.email.$invalid) {
                    this.loading = true;

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'post', url: 'forgot-password', data: {email: this.email}
                    });

                    this.loading = false;

                    // successful response
                    if (!response.response) {
                        this.clearForm();
                    }
                }
            },
            // clear form data
            clearForm() {
                this.$v.email.$reset();
                this.email = null;
            }
        }
    }
</script>